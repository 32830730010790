











































import { Vue, Component } from "vue-property-decorator";
import PageTitle from "@/components/home/activityReview_2023/ActivityTitle.vue";
import ReviewVideo from "@/components/home/activityReview_2023/ReviewVideo.vue";
import AchieveShow from "@/components/home/first/AchieveShow.vue";
import Project from "@/components/home/activityReview_2023/Project.vue";
import Expert from "@/components/home/activityReview_2023/Expert.vue";
import ProjectStar from "@/components/home/activityReview_2023/ProjectStar.vue";
import ActivitySite from "@/components/home/activityReview_2023/ActivitySite.vue";
import CaseShow2021 from "@/components/home/2021CaseShow.vue";
@Component({
  components: {
    "page-title": PageTitle,
    "review-video": ReviewVideo,
    achieveshow: AchieveShow,
    project: Project,
    expert: Expert,
    projectstar: ProjectStar,
    activitysite: ActivitySite,
    CaseShow2021: CaseShow2021,
  },
})
export default class ActivityReview2022 extends Vue {}
