









































































































































import { Vue, Component } from 'vue-property-decorator';
import {
    Swiper as SwiperClass,
    Pagination,
    Mousewheel,
    Autoplay,
    Navigation,
    Scrollbar,
    EffectCoverflow
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.css";
SwiperClass.use([
    Pagination,
    Mousewheel,
    Autoplay,
    Navigation,
    Scrollbar,
    EffectCoverflow
]);
Vue.use(getAwesomeSwiper(SwiperClass));
@Component({})
export default class CaseShow2020 extends Vue{

     /**项目轮播参数 */
    swiperOption = {
        direction: "vertical",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        slidesPerView: 3,
        spaceBetween: 10,
        centeredSlides: false,
        centeredSlidesBounds: true,
        loop: true,
        speed: 3000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false
        },
        scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true
        }
    };

    /**项目轮播数据 */
    swiperData_1: any = [
        // {
        //     id: "1",
        //     coverimg: "http://testgc.ittn.com.cn/baijia/img/back.jpg",
        //     jgname: "123"
        // }
    ];
    swiperData_2: any = [];
    swiperData_3: any = [];
    swiperData_4: any = [];

    iskeepAutoplay: boolean = false;

    showProjectSwiper: boolean = true; //是否显示项目列表轮播

    getCaseList() {
        this.$axios({
            method: "get",
            url: "http://ittn.com.cn/api/bqtop"
        }).then((res: any) => {
            this.swiperData_1 = res.data[1].data;
            this.swiperData_2 = res.data[2].data;
            this.swiperData_3 = res.data[3].data;
            this.swiperData_4 = res.data[4].data;
        });
    }

    handelCaseItem(e: any, item: any, index: any) {
        this.$router.push({ name: "project2020", params: { pid: item.id } });
    }

    mounted() {             
        this.iskeepAutoplay = true;
        this.getCaseList();
    }

    

    
}
