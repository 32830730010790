


















































import { Vue, Component } from "vue-property-decorator";
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  Scrollbar,
  EffectCoverflow,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.css";
SwiperClass.use([
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  Scrollbar,
  EffectCoverflow,
]);
Vue.use(getAwesomeSwiper(SwiperClass));
@Component({})
export default class CaseShow2021 extends Vue {
  /**领域列表
   * 键即为领域的id */
  region: any = {};

  /**项目列表
   * 键即为所属领域的id */
  swiperData: {} = {};

  /**项目轮播参数 */
  swiperOption = {
    direction: "vertical",
    observer: true, //修改swiper自己或子元素时，自动初始化swiper
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    slidesPerView: 3,
    spaceBetween: 10,
    centeredSlides: false,
    centeredSlidesBounds: true,
    loop: true,
    speed: 3000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
    },
  };

  iskeepAutoplay: boolean = false;

  showProjectSwiper: boolean = true; //是否显示项目列表轮播

  /**获取项目领域 */
  async getRegion() {
    const getOpt = this.$api.apiList.projectType2023;
    const opt = getOpt();

    return await this.$axios({
      method: opt.method,
      url: opt.url,
      headers: opt.headers,
    }).then((res: any) => {
      return res.data.data;
    });
  }

  /**获取项目列表 */
  getCaseList(region?: any) {
    const getOpt = this.$api.apiList.projectList_2023;
    const opt = getOpt(region);

    this.$axios({
      method: opt.method,
      url: opt.url,
    }).then((res: any) => {
      // console.log(Array.isArray(res.data.data));

      this.$set(this.swiperData, region, res.data.data);
    });
  }

  handelCaseItem(e: any, item: any, index: any) {
    this.$router.push({ name: "project2021", params: { pid: item.id } });
  }

  mounted() {
    this.getRegion().then((res: any) => {
      this.region = res;
      const region = this.region;
      for (const key in region) {
        if (Object.prototype.hasOwnProperty.call(region, key)) {
          const element = region[key];
          this.getCaseList(key);
        }
      }
    });
    this.iskeepAutoplay = true;
  }
}
