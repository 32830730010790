








































































import { Vue, Component } from "vue-property-decorator";
import {
    Swiper as SwiperClass,
    Pagination,
    Mousewheel,
    Autoplay,
    Navigation,
    Scrollbar,
    EffectCoverflow,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.css";
SwiperClass.use([
    Pagination,
    Mousewheel,
    Autoplay,
    Navigation,
    Scrollbar,
    EffectCoverflow,
]);
Vue.use(getAwesomeSwiper(SwiperClass));
@Component({})
export default class ExpertShow_2020 extends Vue {
    /**项目列表
     * 键即为所属领域的id */
    swiperData: {} = {};

    /**项目轮播参数 */
    swiperOption = {
        direction: "vertical",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        slidesPerView: 3,
        spaceBetween: 10,
        centeredSlides: false,
        centeredSlidesBounds: true,
        loop: true,
        speed: 3000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true,
        },
    };

    iskeepAutoplay: boolean = false;

    showProjectSwiper: boolean = true; //是否显示项目列表轮播

    /**获取专家列表 */
    getCaseList() {
        const getOpt = this.$api.apiList.expertList_2020;
        const opt = getOpt();

        this.$axios({
            method: opt.method,
            url: opt.url,
        }).then((res: any) => {
            this.swiperData = res.data;
        });
    }

    handelCaseItem(e: any, item: any, index: any) {
        this.$router.push({ name: "expert2021", params: { eid: item.id } });
    }

    mounted() {
        this.getCaseList();

        this.iskeepAutoplay = true;
    }
}
