





















import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
    },
})
export default class ActivitySite extends Vue {
    get picArr() {
        let opt: {}[] = [
            {
                url: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E5%9B%9E%E9%A1%BE2021/%E7%8E%B0%E5%9C%BA/1.jpg",
            },
            {
                url: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E5%9B%9E%E9%A1%BE2021/%E7%8E%B0%E5%9C%BA/2.jpg",
            },
            {
                url: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E5%9B%9E%E9%A1%BE2021/%E7%8E%B0%E5%9C%BA/3.jpg",
            },
            {
                url: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E5%9B%9E%E9%A1%BE2021/%E7%8E%B0%E5%9C%BA/4.jpg",
            },
            {
                url: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E5%9B%9E%E9%A1%BE2021/%E7%8E%B0%E5%9C%BA/5.jpg",
            },
            {
                url: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E5%9B%9E%E9%A1%BE2021/%E7%8E%B0%E5%9C%BA/6.jpg",
            },
        ];
        return opt;
    }
}
