

















import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";

@Component({
  components: {
    "cont-item": ContItem,
    "cont-title-pro": ContItemTitle,
    "cont-content-box": ContItemContent,
    "box-item": BoxItem,
  },
})
export default class ActivitySite extends Vue {
  get picArr() {
    let opt: {}[] = [
      {
        url: "http://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/huodong1.png",
      },
      {
        url: "http://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/huodong2.png",
      },
      {
        url:
          "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/WechatIMG1640.jpg",
      },
      {
        url: "http://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/huodong4.png",
      },
      {
        url:
          "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/WechatIMG1641.jpg",
      },
      {
        url:
          "https://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/WechatIMG1642.jpg",
      },
    ];
    return opt;
  }
}
