







































import { Vue, Component } from "vue-property-decorator";
import PageTitle from "@/components/home/first/FirstActivityTitle.vue";
import ReviewVideo from "@/components/home/first/ReviewVideo.vue";
import AchieveShow from "@/components/home/first/AchieveShow.vue";
import ActivitySite from "@/components/home/first/ActivitySite.vue";
import Project from "@/components/home/first/Project.vue";
import Expert from "@/components/home/first/Expert.vue";

@Component({
    components: {
        "page-title": PageTitle,
        "review-video": ReviewVideo,
        achieveshow: AchieveShow,
        activitysite: ActivitySite,
        project: Project,
        expert:Expert
    },
})
export default class FirstActivity extends Vue {}
