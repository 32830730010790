




































































































import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import "@/components/al/ImportJs";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
    },
})
export default class Contact extends Vue {
    get img_src() {
        const url_base =
            "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/";
        let url =
            this.$i18n.locale == "en"
                ? `${url_base}bestech_2020_lightpoint_en.png`
                : `${url_base}bestech_2020_lightpoint_zh.png`;
        return url;
    }

    video_playerId = "video_player"; //视频播放器id
    video_coverImg =
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E7%99%BE%E5%BC%BA%E8%A7%86%E9%A2%91%E5%B0%81%E9%9D%A2.png"; //视频封面
    video_url =
        "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E7%99%BE%E5%BC%BA.mp4"; //视频url

    //初始化视频播放器
    setVideo(playerId: any, url: any, auto?: boolean, coverImg?: any) {
        //初始化视频
        // @ts-ignore
        var player = new Aliplayer(
            {
                id: playerId,
                width: "100%",
                height: "100%",
                autoplay: auto,
                cover: coverImg ? coverImg : undefined,
                source: url,
                isLive: false,
                rePlay: true,
                playsinline: true,
                preload: true,
                controlBarVisibility: "hover",
                useH5Prism: true,
            },
            function (player: any) {
                // console.log("关于ITCP培训模块，视频播放器创建好了。");
            }
        );
    }

    //外部js文件加载完毕钩子
    isLoaded(e: any) {
        this.setVideo(
            this.video_playerId,
            this.video_url,
            true,
            this.video_coverImg
        );
        this.setVideo(
            "achieveShow-video__player",
            "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E5%9B%9E%E9%A1%BE2021/%E5%88%98%E6%B4%AA%E6%B6%9B%E7%A7%98%E4%B9%A6%E9%95%BF%E8%87%B4%E8%BE%9E.mp4"
        );
    }

    //成果展示
    achieves = {
        zh: [
            {
                data: "10",
                content: "大领域",
            },
            {
                data: "36",
                content: "国家与地区",
            },
            {
                data: "2800+",
                content: "全球优质项目",
            },
            {
                data: "94",
                content: "领域专家、知名投资人",
            },
            {
                data: "10",
                content: "顶尖院士专家",
            },
            {
                data: "50+",
                content: "项目路演",
            },
            {
                data: "550+",
                content: "有效对接",
            },
            {
                data: "80+",
                content: "合作意向",
            },
        ],
        en: [
            {
                data: "10",
                content: "Hot industries",
            },
            {
                data: "36",
                content: "Countries/Regions",
            },
            {
                data: "2800+",
                content: "High-quality projects worldwide",
            },
            {
                data: "94",
                content: "Exerienced experts",
            },
            {
                data: "10",
                content: "Academicians as final round jury",
            },
            {
                data: "50+",
                content: "Roadshows",
            },
            {
                data: "550+",
                content: "Efficient matchmaking achieved",
            },
            {
                data: "80+",
                content: "Cooperation reached",
            },
        ],
    };

    //10个领域
    regions = {
        zh: [
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xinyidaixinxijishu.png",
                name: "新一代信息技术",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/zhinengzhizao.png",
                name: "智能制造",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/shengwujishu.png",
                name: "生物技术与创新药",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/gaoduanyiliao.png",
                name: "高端医疗器械",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/huanbaoyukechixufazhan.png",
                name: "环保与可持续发展",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xinnengyuan.png",
                name: "新能源",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xincailiao.png",
                name: "新材料",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xiandainongye.png",
                name: "现代农业",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/zhihuijiaotong.png",
                name: "智慧交通",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/gaoduanzhuangbei.png",
                name: "高端装备",
            },
        ],
        en: [
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xinyidaixinxijishu.png",
                name: "New Generation of ICT",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/zhinengzhizao.png",
                name: "Intelligent Manufacturing",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/shengwujishu.png",
                name: "Biotechnology & Innovative Medicine",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/gaoduanyiliao.png",
                name: "High-end Medical Device",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/huanbaoyukechixufazhan.png",
                name: "Environmental Protection & Sustainable Development",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xinnengyuan.png",
                name: "New Energy",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xincailiao.png",
                name: "New Materials",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/xiandainongye.png",
                name: "Modern Agriculture",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/zhihuijiaotong.png",
                name: "Smart Transportation",
            },
            {
                icon: "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/10%E5%A4%A7%E9%A2%86%E5%9F%9F/gaoduanzhuangbei.png",
                name: "High-end Equipment",
            },
        ],
    };

    //终审专家
    experts = {
        zh: [
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/2C1BE82A_4AB13298.jpg",
                region: "新一代信息技术",
                name: "崔伟宏",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/528692E4_1A460756.jpg",
                region: "智能制造",
                name: "邓中亮",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/FDC855FD_60A02A1D.jpg",
                region: "智慧交通",
                name: "钟耳顺",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/E463A26F_4BFD30F1.png",
                region: "新材料",
                name: "王文魁",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/微信截图_20210924204403.png",
                region: "新能源",
                name: "金涌",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/3BE3FE10_3E52BF3F.jpg",
                region: "环保与可持续发展",
                name: "周少奇",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/1632677124(1).jpg",
                region: "高端装备",
                name: "李中",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/d058ccbf6c81800a19d869390c7d24fa828ba61ee9f9.jpg",
                region: "现代农业",
                name: "朱明",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/7a899e510fb30f24872cc713cc95d143ad4b036f.jpg",
                region: "高端医疗器械",
                name: "王卫东",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/微信截图_20210924200810.png",
                region: "生物技术与创新药",
                name: "何如意",
            },
        ],
        en: [
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/2C1BE82A_4AB13298.jpg",
                region: "New Generation of ICT",
                name: "Cui Weihong",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/528692E4_1A460756.jpg",
                region: "Intelligent Manufacturing",
                name: "Deng Zhongliang",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/FDC855FD_60A02A1D.jpg",
                region: "Smart Transportation",
                name: "Zhong Ershun",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/E463A26F_4BFD30F1.png",
                region: "New Materials",
                name: "Wang Wenkui",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/微信截图_20210924204403.png",
                region: "New Energy",
                name: "Jin Yong",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/3BE3FE10_3E52BF3F.jpg",
                region: "Environmental Protection & Sustainable Development",
                name: "Zhou Shaoqi",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/1632677124(1).jpg",
                region: "High-end Equipment",
                name: "Li Zhong",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/d058ccbf6c81800a19d869390c7d24fa828ba61ee9f9.jpg",
                region: "Modern Agriculture",
                name: "Zhu Ming",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/7a899e510fb30f24872cc713cc95d143ad4b036f.jpg",
                region: "High-end Medical Device",
                name: "Wang Weidong",
            },
            {
                headimg:
                    "http://ittn.oss-cn-beijing.aliyuncs.com/ittn_projects/images/微信截图_20210924200810.png",
                region: "Biotechnology & Innovative Medicine",
                name: "He Ruyi",
            },
        ],
    };
}
