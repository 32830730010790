















import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import "@/components/al/ImportJs";

@Component({
  components: {
    "cont-item": ContItem,
    "cont-title-pro": ContItemTitle,
    "cont-content-box": ContItemContent,
    "box-item": BoxItem,
  },
})
export default class ProjectStar extends Vue {
  get img_src() {
    let url =
      this.$i18n.locale == "en"
        ? `http://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/mingxingen.png`
        : `http://ittn.oss-cn-beijing.aliyuncs.com/2024bestech/mingxingcn.png`;
    return url;
  }
}
