
































import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import "@/components/al/ImportJs";
import CaseShow2023 from "@/components/home/2023CaseShow.vue";

@Component({
  components: {
    "cont-item": ContItem,
    "cont-title-pro": ContItemTitle,
    "cont-content-box": ContItemContent,
    "box-item": BoxItem,
    Caseshow: CaseShow2023,
  },
})
export default class Project extends Vue {
  video_playerId = "video_player_1"; //视频播放器id
  video_coverImg =
    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E7%99%BE%E5%BC%BA%E8%A7%86%E9%A2%91%E5%B0%81%E9%9D%A2.png"; //视频封面
  video_url =
    "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/x100%E5%90%8D%E5%8D%95HD.mp4"; //视频url

  //初始化视频播放器
  setVideo(playerId: any, url: any, coverImg: any, mute?: boolean) {
    //初始化视频
    // @ts-ignore
    var player = new Aliplayer(
      {
        id: playerId,
        width: "100%",
        height: "100%",
        autoplay: false,
        cover: coverImg,
        source: url,
        isLive: false,
        rePlay: true,
        playsinline: true,
        preload: true,
        controlBarVisibility: "hover",
        useH5Prism: true,
      },
      function(player: any) {
        // console.log("关于ITCP培训模块，视频播放器创建好了。");
        if (mute) {
          player.mute();
        }
      }
    );
  }

  //外部js文件加载完毕钩子
  isLoaded(e: any) {
    this.setVideo(this.video_playerId, this.video_url, this.video_coverImg);
  }
}
