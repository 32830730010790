















import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
import "@/components/al/ImportJs";

@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem,
    },
})
export default class ProjectStar extends Vue {
    get img_src() {
        const url_base =
            "https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/%E5%9B%9E%E9%A1%BE2021/";
        let url =
            this.$i18n.locale == "en"
                ? `${url_base}projectStar_en.png`
                : `${url_base}projectStar_zh.png`;
        return url;
    }
}
