













































import { Vue, Component } from "vue-property-decorator";
import PageTitle from "@/components/home/activityReview_2021/ActivityTitle.vue";
import ReviewVideo from "@/components/home/activityReview_2021/ReviewVideo.vue";
import AchieveShow from "@/components/home/first/AchieveShow.vue";
import Project from "@/components/home/activityReview_2021/Project.vue";
import Expert from "@/components/home/activityReview_2021/Expert.vue";
import ProjectStar from "@/components/home/activityReview_2021/ProjectStar.vue";
import ActivitySite from "@/components/home/activityReview_2021/ActivitySite.vue"

@Component({
    components: {
        "page-title": PageTitle,
        "review-video": ReviewVideo,
        achieveshow: AchieveShow,
        project: Project,
        expert:Expert,
        projectstar:ProjectStar,
        activitysite:ActivitySite
    },
})
export default class ActivityReview2022 extends Vue {}
